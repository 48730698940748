import { graphql, Link, StaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import { getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"
import parse from "html-react-parser"
import React from "react"

const RelatedArticleItems = () => {
  return (
    <StaticQuery
      query={graphql`
        {
          posts: allWpArticle(limit: 3, sort: { fields: date, order: DESC }) {
            nodes {
              databaseId
              title
              slug
              uri
              excerpt
              featuredImage {
                node {
                  altText
                  localFile {
                    childImageSharp {
                      gatsbyImageData(placeholder: BLURRED)
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={data => {
        const posts = data.posts.nodes

        return (
          <>
            {posts.map(post => {
              const postsRelImg = getImage(
                post.featuredImage?.node?.localFile?.childImageSharp
                  ?.gatsbyImageData
              )

              const postsRelImgBg = convertToBgImage(postsRelImg)
              const postsRelImgAlt = post.featuredImage?.node?.altText

              return (
                <article
                  key={post.databaseId}
                  className="Content patterns-articles type-patterns-articles status-publish has-post-thumbnail hentry"
                >
                  <Link to={`/resources/articles/${post.slug}`}>
                    <div className="Content-thumbnail">
                      <BackgroundImage
                        Tag="div"
                        {...postsRelImgBg}
                        className="Content-thumbnail-image"
                      ></BackgroundImage>
                    </div>
                    <div className="Content-text">
                      <header className="Content-header">
                        <h3 className="Content-title">{post.title}</h3>
                      </header>

                      <div className="Content-excerpt">
                        {parse(post.excerpt)}
                      </div>
                    </div>
                  </Link>
                </article>
              )
            })}
          </>
        )
      }}
    ></StaticQuery>
  )
}

export default RelatedArticleItems
